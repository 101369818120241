<template>
  <div class="userform">
    <!-- header -->
    <div class="userform__header">
      <div v-bind:class="{ userform__header_item_active: userFormStep == 0 }" class="userform__header_item">
        <span class="userform__header_item_number">1</span>
        <span class="userform__header_item_label">Deine Infos</span>
      </div>
      <img class="userform__header_next" alt="Next Icon" src="@/assets/images/icons/next_icon.png">  
      <div v-bind:class="{ userform__header_item_active: userFormStep == 1 }" class="userform__header_item">
        <span class="userform__header_item_number">2</span>
        <span class="userform__header_item_label">Zahlart</span>
      </div>
      <img class="userform__header_next" alt="Next Icon" src="@/assets/images/icons/next_icon.png">  
      <div v-bind:class="{ userform__header_item_active: userFormStep == 2 }" class="userform__header_item">
        <span class="userform__header_item_number">3</span>
        <span class="userform__header_item_label">Bestätigung</span>
      </div>
    </div>

    <!-- step 01 -->
    <div v-if="userFormStep == 0" class="userform__step">
      <form
        id="userForm"
        class="form"
      >
      <h3>Deine Lieferadresse</h3>
        <span>
          <label for="name">Vorname</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && store.userData.name == '' || checkedValidation && store.userData.name.length < 2}"
            id="name"
            v-model="store.userData.name"
            type="text"
            name="name"
          >
        </span>

        <span>
          <label for="surname">Nachname</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && store.userData.surname == '' || checkedValidation && store.userData.surname.length < 2}"
            id="surname"
            v-model="store.userData.surname"
            type="text"
            name="surname"
          >
        </span>

        <span>
          <label for="street">Strasse</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && store.userData.street == '' || checkedValidation && store.userData.street.length < 2}"
            id="street"
            v-model="store.userData.street"
            type="text"
            name="street"
          >
        </span>

        <span>
          <label for="plz">PLZ</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && store.userData.plz == '' || checkedValidation && store.userData.plz.length != 5}"
            id="plz"
            v-model="store.userData.plz"
            type="text"
            name="plz"
          >
        </span>

        <span>
          <label for="city">Stadt</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && store.userData.city == '' || checkedValidation && store.userData.city.length < 2}"
            id="city"
            v-model="store.userData.city"
            type="text"
            name="city"
          >
        </span>

        <span>
          <label for="additionalInformation">Adresszusatz</label>
          <input
            id="additionalInformation"
            v-model="store.userData.additionalInformation"
            type="text"
            name="additionalInformation"
          >
        </span>

         <h3>Deine Emailadresse</h3>
        <span>
          <label for="email">Email</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && !checkEmail}"
            id="email"
            v-model="store.userData.email"
            type="text"
            name="email"
          >
        </span>
        <span>
          <label for="emailConfirm">Email bestätigen</label>
          <input
            v-bind:class="{ userform__input_nonvalid: checkedValidation && !checkEmailEquals}"
            id="emailConfirm"
            v-model="store.userData.emailConfirm"
            type="text"
            name="emailConfirm"
          >
        </span>
        <span v-bind:class="{ userform__input_nonvalid_label: checkedValidation && !store.userData.termsChecked}" class="tac_checkbox">
          <input class="checkbox" type="checkbox" id="termsCheckbox" v-model="store.userData.termsChecked">
          <p class="checkbox_label" for="termsCheckbox">Ich akzeptiere die <router-link to="/tacs#datenschutz"><span>Datenschutzbedingungen</span></router-link></p>
        </span>
      </form>
      <p v-if="!fieldsAreValid && checkedValidation" class="validation_text">Bitte überprüfe die in rot markieten Eingabefelder!</p>
      <div class="button shadow-box" v-on:click="sendUserData()">
        Zum nächsten Schritt 
      </div>
    </div>

     <!-- step 02 -->
    <div v-if="userFormStep == 1" class="userform__step">
      <h3>Deine Zahlart</h3>
      <form
        id="payForm"
        class="pay_form"
      >
          <!--
          <span class="userform_payment-box">
          <input disabled="true" type="radio" id="payDirect" value="payDirect" v-model="store.userData.payMethod">
          <label class="pay_direct" for="payDirect">Paydirect (Demnächst verfügbar)</label>
          </span>
          -->
          <span class="userform_payment-box">
          <input type="radio" id="prePay" value="prePay" v-model="store.userData.payMethod">
          <label for="prePay">Vorkasse</label>
          </span>
      </form>
       <h3>Deine Spenden gehen an</h3>
      <div v-bind:class="{ pay_slider_disabled: store.userData.secondBandDecision}" class="pay_slider">
        <div v-for="payChoice in store.userData.payChoices" :key="payChoice.organisation">
          <span for="{payChoice.organisationName}" class="pay_slider_label">{{payChoice.organisationName}}<span class="pay_slider_value">{{payChoice.betrag}} €</span></span>
          <vue-range-slider @drag-end="dragPaymentEndChoice()" v-model="payChoice.betragInProzent"></vue-range-slider>
        </div>
      </div>

      <div class="pay_form">
        <span>
          <input class="checkbox" type="checkbox" id="donationCheckbox" v-model="store.userData.secondBandDecision">
         <p class="checkbox_label checkbox_label_small" for="donationCheckbox"><span>Second Bandshirt soll für mich über die Verteilung der Spenden entscheiden</span> <router-link to="/donations"><span>Zur Spendenverteilung</span></router-link></p>
         </span>
      </div>
      <div class="button shadow-box" v-on:click="sendPaymentData()">
        Zum nächsten Schritt 
      </div>
    </div>
    


    <!-- step 03 -->
    <div v-if="userFormStep == 2" class="userform__step userform__summary">
      <h3>Zusammenfassung</h3>

      <h4>Lieferadresse</h4>
      <div class="userform__summary_box">
        <p>{{store.userData.name}} {{store.userData.surname}}</p>
        <p>{{store.userData.street}}</p>
        <p>{{store.userData.plz}}</p>
        <p>{{store.userData.city}}</p>
        <span v-on:click="checkPaymentData(0)" class="edit_link"><img class="userform__header_next" alt="Pencil Icon" src="@/assets/images/icons/pencil_icon.png">Bearbeiten</span>
      </div>

      <h4>E-Mail Adresse</h4>
      <div class="userform__summary_box">
        <p>{{store.userData.email}}</p>
        <span v-on:click="checkPaymentData(0)" class="edit_link"><img class="userform__header_next" alt="Pencil Icon" src="@/assets/images/icons/pencil_icon.png">Bearbeiten</span>
      </div>

      <h4>Zahlart</h4>
      <div class="userform__summary_box">
        <p>Überweisung</p>
        <p><b>Kontoinhaber: Second Bandshirt e.V.</b></p>
        <p>IBAN: DE81 8309 4495 0003 3373 83</p>
        <p>BLZ: 83094495</p>
        <span v-on:click="checkPaymentData(1)" class="edit_link"><img class="userform__header_next" alt="Pencil Icon" src="@/assets/images/icons/pencil_icon.png">Bearbeiten</span>
      </div>

      <div class="userform__summary_box basket_box">
        <div class="basket__summary">
          <div class="basket__summary_item">
            <span class="basket__summary_item_left">Zwischensumme:</span><span class="basket__summary_item_right">{{getCurrentPrice.toFixed(2)}} €</span>
          </div>
          <div class="basket__summary_item">
            <span class="basket__summary_item_left">Versand:</span><span class="basket__summary_item_right">{{store.userData.porto.toFixed(2)}} €</span>
          </div>
        </div>
        <div class="basket__summary">
          <div class="basket__summary_item">
            <span class="basket__summary_item_left"><span class="basket__summary_item_big">Gesamtsumme</span> inkl. MwST:</span><span class="basket__summary_item_right basket__summary_item_big">{{(getCurrentPrice+store.userData.porto).toFixed(2)}} €</span>
          </div>
        </div>
      </div>
      <div class="button shadow-box button_buy" v-on:click="completePaymentData()">
        Jetzt Kaufen
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';
import 'vue-range-component/dist/vue-range-slider.css'
import VueRangeSlider from 'vue-range-component'

export default {
  name: 'UserForm',
  components: {
    VueRangeSlider
  },
  data() {
    return {
      store,
      userFormStep : 0,
      checkedValidation: false,
      fieldsAreValid: false,
      sliderOneValue :33.33,
      sliderTwoValue :33.33,
      sliderThreeValue :33.33,
    };
  },
   mounted: function()
  {
    VueRangeSlider.methods.handleKeyup = ()=> console.log;
    VueRangeSlider.methods.handleKeydown = ()=> console.log;
    setTimeout(() => this.checkStatus(),300);
  },
  computed: {
    checkEmail: function () {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(store.userData.email.toLowerCase()))
      {
        return true;
      }else{
        return false;
      }
    },
    checkEmailEquals: function () {
     if (store.userData.email.toLowerCase() == store.userData.emailConfirm.toLowerCase())
      {
        return true;
      }else{
        return false;
      }
    },
    getCurrentPrice: function () {
      var price = 0;
      for(var i = 0; i < this.store.userData.products.length; i++){
        price += this.store.userData.products[i].preis;
      }
      return price
    },
  },
  methods: {
    dragPaymentEndChoice () {
      this.calculateDonations();
    },
    calculateDonations(){
      var allPercentages = 0;
      
      for(var i = 0; i <  this.store.userData.payChoices.length; i++){
        var percentage = this.store.userData.payChoices[i].betragInProzent;
        //get all values
        allPercentages += percentage;
      }

      for(var j = 0; j <  this.store.userData.payChoices.length; j++){
        var currentMoneyValue = (this.store.userData.payChoices[j].betragInProzent / allPercentages * this.getCurrentPrice).toFixed(2);
        this.store.userData.payChoices[j].betrag = currentMoneyValue;
      }
    },
    checkStatus: function()
    {
      if(store.userData.products.length == 0){
        this.$router.push('shop');
      }
    },
    sendPaymentData(){
      this.userFormStep = 2;
      window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

    },
    completePaymentData(){
      this.$router.push('orderComplete');
      this.userFormStep = 0;
    },
    checkPaymentData(index){
      this.userFormStep = index;
      window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    },
    
    sendUserData(){
      this.checkedValidation = true;

      if(store.userData.name != ""
        && store.userData.surname != ""
        && store.userData.street != ""
        && store.userData.city != ""
        && store.userData.plz != ""
        && store.userData.name.length > 1
        && store.userData.surname.length > 1
        && store.userData.street.length > 1
        && store.userData.city.length > 1
        && store.userData.plz.length == 5
        && this.checkEmail
        && this.checkEmailEquals
        && store.userData.termsChecked
        )
        {
          this.fieldsAreValid = true;
          this.checkedValidation = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.userFormStep = 1;
          this.calculateDonations();
        }else{
          this.fieldsAreValid = false;
           window.scrollTo({
            top: 220,
            left: 0,
            behavior: 'smooth'
          });
        }
    }
  }
}
</script>

<style lang="scss">
  .userform{
    padding-bottom: 20px;
    margin-bottom: 50px;
    @media only screen and (min-width: 612px){ 
     max-width: 750px;
      margin: auto;
    }
    .basket__summary{
      padding: 0;
    }
    .basket_box{
      margin-top: 30px;
      padding-bottom: 40px;
    }
    .button{
      width: 270px;
      margin: auto;
    }
    .button_buy{
      margin-top: -25px;
    }
    &__header{
      text-align: center;
      width: 100%;
      margin-top: 130px;
      &_item{
        display: inline-block;
        font-family: 'Bebas Neue', sans-serif;    
        text-transform: uppercase;
        font-weight: normal;
        text-align: left;
        width: 80px;
        &_number{
          width: 40px;
          height: 40px;
          background-color: $color-secondary;
          font-size: 22px;
          display: block;
          text-align: center;
          color: white;
          line-height: 44px;
          display: inline-block;
          margin-bottom: 10px;
          margin-left: 20px;
          transition: all 0.3;
        }
        &_label{
          display: block;
          font-size: 16px;
          color: black;
          text-align: center;
          transition: all 0.3;
        }
      }
      &_next{
        display: inline-block;
        width: 25px;
        margin-bottom: 40px;
      }
    } 
    &__summary{
      padding: $outer-padding;
      h3{
        padding-left: 0;
        padding-bottom: 0;
      }
      h4{
        padding-bottom: 10px;
        padding-top: 30px;
      }
      &_box{
        display: block;
        padding: 10px;
        background-color: white;
        p{
          padding: 0;
          margin: 0;
          text-align: left;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    } 
  }
  .userform__header_item_active{
    .userform__header_item_number{
      background-color: $color-primary;
    }
    .userform__header_item_label{
      color: $color-primary;
    }
  }

  .userform__input_nonvalid{
    border-left: 4px $color-primary solid!important;
  }
  .userform__input_nonvalid_label{
      border-left: 4px $color-primary solid;
      /* padding-left: 5px!important; */
      margin-left: 0px;
      margin-right: 20px;
  }
  .edit_link{
    margin-top: 10px;
    color: #9F9F9F;
    display: flex;
    font-size: 12px;
    height: 20px;
    overflow: hidden;

    img{
      width: 12px;
      height: 12px;
      padding-right: 5px;
    }
  }
  .pay_direct{
    opacity: 0.5;
  }
  .tac_checkbox{
    padding-left: 15px;
  }
</style>
